<template>
  <div>
    <v-container>
      <v-card-actions>
        <v-spacer />
        <div>
          <AuthorizationLevels class="ma-2" />
        </div>
        <div>
          <AddStaff class="ma-2" />
        </div>
      </v-card-actions>
      <v-data-table
        :headers="dessertHeaders"
        :items="getStaffs"
        item-key="_id"
        show-expand
        :single-expand="true"
        hide-default-footer
        class="elevation-1"
        :loading="TableLoading"
        loading-text="Loading... Please wait"
      >
        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length">
            <div class="display-1">
              Office: {{ item.FulfillmentCenter.locationName }}
            </div>
            <v-card-actions>
              <div>
                <v-img
                  :src="`https://picsum.photos/500/300?image=200`"
                  :lazy-src="`https://picsum.photos/10/6?image=200`"
                  aspect-ratio="1"
                  class="grey lighten-2"
                  max-width="100"
                  max-height="100"
                >
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
                    >
                      <v-progress-circular
                        indeterminate
                        color="grey lighten-5"
                      ></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
                Added By :
                <span v-if="item.addedBy">
                  {{ item.addedBy.personalInfo.firstName }}
                  {{ item.addedBy.personalInfo.lastName }}
                </span>
                <br />
              </div>
              <v-spacer />
              <div class="ma-5">
                <ChangeStaffPosition :staff="item" />
              </div>
              <div class="ma-5">
                <ActivateAndDisableStaff :staff="item" />
              </div>
            </v-card-actions>
            <v-card-actions>
              <v-spacer />
              <StaffActivities :staffId="item._id" />
              <TransferStaff :staff="item" :center="item.FulfillmentCenter" />
              <v-spacer />
              {{ item.null }}
            </v-card-actions>
          </td>
        </template>
      </v-data-table>
      <div class="text-center pt-2">
        <v-pagination v-model="page" :length="totalItems"></v-pagination>
      </div>
    </v-container>
  </div>
</template>
<script>
import moment from "moment";
import StaffActivities from "../components/StaffActivities.vue";
import AddStaff from "../components/AddStaff.vue";
import AuthorizationLevels from "../components/AuthorizationLevels.vue";
import TransferStaff from "../components/TransferStaff.vue";
import ChangeStaffPosition from "../components/ChangeStaffPosition.vue";
import ActivateAndDisableStaff from "../components/ActivateAndDisableStaff.vue";

export default {
  components: {
    StaffActivities,
    AddStaff,
    AuthorizationLevels,
    TransferStaff,
    ChangeStaffPosition,
    ActivateAndDisableStaff,
  },
  data() {
    return {
      page: 1,
      Currpage: 1,
      items: [
        { tab: "Rider", content: "Rider" },
        { tab: "Adminstration", content: "Adminstration" },
      ],
      expanded: [],
      singleExpand: false,
      dessertHeaders: [
        {
          text: "First Name",
          align: "start",
          value: "personalInfo.firstName",
        },
        { text: "Last Name", value: "personalInfo.lastName" },
        { text: "PhoneNumber", value: "personalInfo.phoneNumber" },
        { text: "Position", value: "positionId.positionName" },
      ],
      desserts: [],
    };
  },
  computed: {
    getStaffs() {
      return this.$store.getters.getStaffs;
    },
    getToken() {
      return this.$store.getters.getToken;
    },
    TableLoading() {
      return this.$store.getters.TableLoading;
    },
    totalItems() {
      let total = this.$store.getters.totalItems;
      return Math.ceil(total / 20);
    },
  },
  methods: {
    DisTime(payload) {
      return moment(payload).calendar();
    },
    DisplayTimeFormat(payload) {
      return moment(payload).format("DD MMM YYYY, LTS");
    },
    getAllStaffs() {
      let payload = {
        page: this.page,
        token: this.getToken,
      };
      this.$store.dispatch("getAllStaffs", payload);
    },
  },
  created() {
    this.getAllStaffs();
  },
  watch: {
    page: function (val) {
      if (this.page != this.Currpage) {
        this.Currpage = val;
        let payload = {
          page: this.page,
          token: this.getToken,
        };
        this.$store.dispatch("getAllStaffs", payload);
        return;
      }
    },
  },
};
</script>