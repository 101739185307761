<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent max-width="590">
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="primary" dark v-bind="attrs" v-on="on">
          Available Position
        </v-btn>
      </template>
      <v-card>
        <v-card-title class="text-h5"> Available Position </v-card-title>
        <v-card-actions>
          <v-spacer />
          <div>
            <CreatePosition class="ma-1" />
          </div>
        </v-card-actions>
        <v-data-table
          :headers="dessertHeaders"
          :items="StaffPosition"
          show-expand
          item-key="_id"
          :single-expand="true"
          hide-default-footer
          @page-count="pageCount = $event"
          class="elevation-1"
        >
          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length">
              <v-combobox
                v-model="item.roles"
                label="System Authorization"
                multiple
                chips
                disabled
              >
                <template v-slot:selection="data">
                  <v-chip
                    :key="JSON.stringify(data.item)"
                    v-bind="data.attrs"
                    :input-value="data.selected"
                    :disabled="data.disabled"
                    @click:close="data.parent.selectItem(data.item)"
                  >
                    <v-avatar
                      class="accent white--text"
                      left
                      v-text="data.item.slice(0, 1).toUpperCase()"
                    ></v-avatar>
                    {{ data.item }}
                  </v-chip>
                </template>
              </v-combobox>
              {{ item.null }}
            </td>
          </template>
        </v-data-table>
        <div class="text-center pt-2">
          <v-pagination
            v-model="page"
            :length="PositionTotalItems"
          ></v-pagination>
        </div>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="dialog = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import CreatePosition from "./Position.vue";
export default {
  components: {
    CreatePosition,
  },
  data() {
    return {
      dialog: false,
      expanded: [],
      page: 1,
      Currpage: 1,
      singleExpand: false,
      dessertHeaders: [
        {
          text: "Position Name",
          align: "start",
          value: "positionName",
        },
        { text: "Roles NO", align: "center", value: "roles.length" },
      ],
      select: ["Vuetify", "Programming"],
    };
  },
  computed: {
    Roles() {
      return this.$store.getters.Roles;
    },
    StaffPosition() {
      return this.$store.getters.StaffPosition;
    },
    getToken() {
      return this.$store.getters.getToken;
    },
    PositionTotalItems() {
      let total = this.$store.getters.staffWalletTransactionTotalItems;
      return Math.ceil(total / 20);
    },
  },
  methods: {
    GetAvailableStaffPosition() {
      let payload = {
        token: this.getToken,
        page: this.page,
      };
      this.$store.dispatch("getAvailableStaffPosition", payload);
      this.dialog = false;
    },
  },
  created() {
    this.GetAvailableStaffPosition();
  },
  watch: {
    page: function (val) {
      if (this.page != this.Currpage) {
        this.Currpage = val;
        let payload = {
          page: this.page,
          token: this.getToken,
        };
        this.$store.dispatch("getAvailableStaffPosition", payload);
        return;
      }
    },
  },
};
</script>