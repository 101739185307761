<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent max-width="390">
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-if="staff.isActive" dark v-bind="attrs" v-on="on">
          Disable
        </v-btn>
        <v-btn v-else color="pink" dark v-bind="attrs" v-on="on">
          Activate
        </v-btn>
      </template>
      <v-card>
        <v-card-title>
          <div>Are you sure you want to "</div>
          <br />
          <div v-if="staff.isActive">Disable"</div>
          <div v-else>Activate"</div>
          <br />
          <div>
            {{ staff.personalInfo.firstName }}
            {{ staff.personalInfo.lastName }} ?
          </div>
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="dialog = false">
            Close
          </v-btn>
          <v-btn color="pink" @click="activateAndDisableStaff"> Proceed </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
export default {
  props: ["staff"],
  data() {
    return {
      dialog: false,
    };
  },
  computed: {
    getToken() {
      return this.$store.getters.getToken;
    },
  },
  methods: {
    activateAndDisableStaff() {
      let payload = {
        userId: this.staff._id,
        token: this.getToken,
        action: !this.staff.isActive,
      };
      this.$store.dispatch("activateAndDisableStaff", payload);
      this.dialog = false;
    },
  },
};
</script>