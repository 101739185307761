<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent max-width="390">
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="warning" dark v-bind="attrs" v-on="on">
          Change Staff Position
        </v-btn>
      </template>
      <v-card>
        <v-card-title class="text-h7">
          {{ staff.personalInfo.firstName }} {{ staff.personalInfo.lastName }}
          <br />
          Current Position: {{ staff.positionId.positionName }}
          <br />
          New Position: {{ newPosition }}
        </v-card-title>
        <v-card-text>
          <v-container fluid>
            <v-row align="center">
              <v-col class="d-flex" cols="12" sm="12">
                <v-select
                  :items="StaffPosition"
                  item-text="positionName"
                  item-value="_id"
                  label="Select Position"
                  dense
                  filled
                  v-model="newPositionId"
                ></v-select>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="dialog = false">
            Close
          </v-btn>
          <v-btn color="pink" @click="ChangeStaffRole()"> Update </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
export default {
  props: ["staff"],
  data() {
    return {
      dialog: false,
      newPosition: "",
      newPositionId: "",
    };
  },
  computed: {
    StaffPosition() {
      return this.$store.getters.StaffPosition;
    },
    getToken() {
      return this.$store.getters.getToken;
    },
  },
  methods: {
    ChangeStaffRole() {
      if (this.newPositionId == "") {
        this.$store.commit("setSnackbar", true);
        this.$store.commit(
          "setServerMessage",
          "Fill Out all the required fields"
        );
        return;
      }
      let payload = {
        userId: this.staff._id,
        token: this.getToken,
        positionId: this.newPositionId,
      };
      this.$store.dispatch("ChangeStaffRole", payload);
      this.dialog = false;
    },
  },
  watch: {
    newPositionId: function (val) {
      this.StaffPosition.forEach((item) => {
        if (item._id == val) {
          this.newPosition = item.positionName;
        }
      });
    },
  },
};
</script>