<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent max-width="600px">
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="warning" dark v-bind="attrs" v-on="on">
          Create Position
        </v-btn>
      </template>
      <v-card>
        <v-card-title>
          <span class="text-h5">Create Position</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-text-field
                  v-model="positionName"
                  label="Position Name"
                  filled
                  shaped
                  required
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="12">
                <v-combobox
                  v-model="selected"
                  label="Select Position Authorization"
                  multiple
                  chips
                  filled
                  shaped
                  :items="Roles"
                >
                  <template v-slot:selection="data">
                    <v-chip
                      :key="JSON.stringify(data.item)"
                      v-bind="data.attrs"
                      :input-value="data.selected"
                      @click:close="data.parent.selectItem(data.item)"
                    >
                      <v-avatar
                        class="accent white--text"
                        left
                        v-text="data.item.slice(0, 1).toUpperCase()"
                      ></v-avatar>
                      {{ data.item }}
                    </v-chip>
                  </template>
                </v-combobox>
              </v-col>
            </v-row>
          </v-container>
          <small>*indicates required field</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialog = false">
            Close
          </v-btn>
          <v-btn color="blue darken-1" text @click="CreateStaffPosition">
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
export default {
  data: () => ({
    dialog: false,
    selected: [],
    positionName: "",
  }),
  computed: {
    Roles() {
      return this.$store.getters.Roles;
    },
    StaffPosition() {
      return this.$store.getters.StaffPosition;
    },
    getToken() {
      return this.$store.getters.getToken;
    },
    totalItems() {
      let total = this.$store.getters.totalItems;
      return Math.ceil(total / 20);
    },
  },
  created() {
    this.getRoles();
  },
  methods: {
    getRoles() {
      let payload = {
        token: this.getToken,
      };
      this.$store.dispatch("getRoles", payload);
    },
    CreateStaffPosition() {
      if (this.positionName == "" || this.selected.length == 0) {
        this.$store.commit("setSnackbar", true);
        this.$store.commit(
          "setServerMessage",
          "Fill Out all the required fields"
        );
        return;
      }

      let payload = {
        token: this.getToken,
        positionName: this.positionName,
        selected: this.selected,
      };
      this.$store.dispatch("createStaffPosition", payload);
      this.dialog = false;
    },
  },
};
</script>