<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent max-width="600px">
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="pink" dark v-bind="attrs" v-on="on"> Add Staff </v-btn>
      </template>
      <v-card>
        <v-card-title>
          <span class="text-h5">Add Staff</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="6" md="4">
                <v-text-field
                  v-model="firstName"
                  label="First name*"
                  required
                  filled
                  shaped
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-text-field
                  v-model="middleName"
                  label="Middle name"
                  filled
                  shaped
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-text-field
                  v-model="lastName"
                  label="Last name*"
                  required
                  filled
                  shaped
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="email"
                  label="Email*"
                  required
                  filled
                  shaped
                ></v-text-field>
                <v-select
                  v-model="position"
                  :items="StaffPosition"
                  label="Select Position*"
                  item-text="positionName"
                  item-value="_id"
                  required
                  filled
                  shaped
                ></v-select>
                <VuePhoneNumberInput
                  default-country-code="NG"
                  v-model="unformatedphoneNumber"
                  @update="results = $event"
                  filled
                  class="mb-4"
                />
              </v-col>
            </v-row>
          </v-container>
          <small>*indicates required field</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialog = false">
            Close
          </v-btn>
          <v-btn color="pink" @click="AddStaff"> Add Staff </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import VuePhoneNumberInput from "vue-phone-number-input";
import "vue-phone-number-input/dist/vue-phone-number-input.css";
export default {
  components: {
    VuePhoneNumberInput,
  },
  data: () => ({
    vehicleType: "",
    results: null,
    unformatedphoneNumber: "",
    dialog: false,
    position: "",
    firstName: "",
    lastName: "",
    middleName: "",
    profileType: "",
    email: "",
    page: 1,
    phoneNumber: "",
  }),

  computed: {
    StaffPosition() {
      return this.$store.getters.StaffPosition;
    },
    getToken() {
      return this.$store.getters.getToken;
    },
  },

  methods: {
    GetAvailableStaffPosition() {
      let payload = {
        token: this.getToken,
        page: this.page,
      };
      this.$store.dispatch("getAvailableStaffPosition", payload);
    },
    AddStaff() {
      if (
        this.firstName == "" ||
        this.lastName == "" ||
        this.phoneNumber == ""
      ) {
        this.$store.commit("setSnackbar", true);
        this.$store.commit(
          "setServerMessage",
          "Fill Out all the required fields"
        );
        return;
      }

      let payload = {
        token: this.getToken,
        firstName: this.firstName,
        lastName: this.lastName,
        middleName: this.middleName,
        positionId: this.position,
        phoneNumber: this.phoneNumber,
      };
      this.$store.dispatch("AddStaff", payload);
      this.dialog = false;
    },
  },
  created() {
    this.GetAvailableStaffPosition();
  },
  watch: {
    results(val) {
      if (val) {
        if (val.formattedNumber) {
          this.phoneNumber = val.formattedNumber;
        }
      }
    },
  },
};
</script>