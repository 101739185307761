<template>
  <div class="text-center">
    <v-dialog v-model="dialog" width="850">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          rounded
          class="ma-2"
          color="primary"
          dark
          v-bind="attrs"
          v-on="on"
        >
          Transfer Staff
        </v-btn>
      </template>

      <v-card>
        <v-card-title class="text-h5" color="primary">
          Transfer :
          {{ staff.personalInfo.firstName }}
          {{ staff.personalInfo.lastName }}
        </v-card-title>
        <v-divider></v-divider>
        <v-card-title>
          From:
          <br />{{ center.locationName }}
        </v-card-title>
        <v-divider class="ma-1"></v-divider>
        <v-simple-table dense>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">Office Address</th>
                <th class="text-left">Transfer</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in AllFulfillmentCenter" :key="item._id">
                <td>{{ item.locationName }}</td>
                <td>
                  <v-btn dack small color="pink" @click="Transfer(item)">
                    Transfer
                  </v-btn>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialog = false"> Close </v-btn>
        </v-card-actions>
        <v-dialog v-model="dialog1" persistent max-width="290">
          <v-card>
            <v-card-title class="text-h6">
              {{ staff.personalInfo.firstName }}
              {{ staff.personalInfo.lastName }}
            </v-card-title>
            <v-card-text>
              <strong> From : </strong>
              <br />
              {{ center.locationName }}
              <br />
              <strong> To : </strong>
              <br />
              {{ TransferStore.locationName }}
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="green darken-1" text @click="dialog1 = false">
                Close
              </v-btn>
              <v-btn color="green darken-1" text @click="SwitchCenter">
                Confirm
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  props: ["center", "staff"],
  data() {
    return {
      dialog: false,
      dialog1: false,
      page: 1,
      Currpage: 1,
      TransferStore: "",
    };
  },
  methods: {
    Transfer(payload) {
      this.dialog1 = true;
      this.TransferStore = payload;
    },
    SwitchCenter() {
      let payload = {
        token: this.getToken,
        centerId: this.TransferStore._id,
        userId: this.staff._id,
        //route: this.$router,
      };
      this.$store.dispatch("TransferStaff", payload);
      this.dialog = false;
      this.dialog1 = false;
    },
  },
  computed: {
    AllFulfillmentCenter() {
      return this.$store.getters.AllFulfillmentCenter;
    },
    getToken() {
      return this.$store.getters.getToken;
    },
    getUser() {
      return this.$store.getters.getUser;
    },
  },
  watch: {
    dialog: function (val) {
      if (val) {
        let payload = {
          token: this.getToken,
          page: this.page,
        };
        this.$store.dispatch("getAllFulfillmentCenter", payload);
      }
    },
    page: function (val) {
      if (this.page != this.Currpage) {
        this.Currpage = val;
        let payload = {
          page: this.page,
          token: this.getToken,
        };
        this.$store.dispatch("getAllFulfillmentCenter", payload);
      }
    },
  },
};
</script>